import React from "react"
import styles from "./social.module.css"
import windowHelper from "./windowHelper"

import LINKEDIN_ICON from "../../images/social/linkedin.svg"
import FACEBOOK_ICON from "../../images/social/facebook.svg"
import PRINT_ICON from "../../images/social/print.svg"
import TWITTER_ICON from "../../images/social/twitter.svg"
import MAIL_ICON from "../../images/social/email.svg"

export default function Social(props) {
  return (
    <div>
      <a
        title="Share to LinkedIn"
        className={styles.container}
        onClick={() =>
          windowHelper(
            `https://www.linkedin.com/shareArticle/?mini=true&url=${encodeURI(
              props.url
            )}&title=${encodeURI(props.title)}`
          )
        }
      >
        <img src={LINKEDIN_ICON} className={styles.icon} />
      </a>

      <a
        title="Email"
        className={styles.container}
        href={`mailto:someone@example.com?Subject=${encodeURI(
          props.title
        )}&body=${encodeURI(props.url)}`}
      >
        <img src={MAIL_ICON} className={styles.icon} />
      </a>

      <a
        title="Share to Facebook"
        className={styles.container}
        onClick={() =>
          windowHelper(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
              props.url
            )}`
          )
        }
      >
        <img src={FACEBOOK_ICON} className={styles.icon} />
      </a>

      <a
        title="Share to Twitter"
        className={styles.container}
        onClick={() =>
          windowHelper(
            `https://twitter.com/intent/tweet?url=${encodeURI(
              props.url
            )}&text=${encodeURI(props.title)}`
          )
        }
      >
        <img src={TWITTER_ICON} className={styles.icon} />
      </a>
      <a
        title="Print this page"
        className={styles.container}
        href=""
        onClick={() => window.print()}
      >
        <img src={PRINT_ICON} className={styles.icon} />
      </a>
    </div>
  )
}
